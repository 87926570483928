import { Grade, GradeColors } from "@vaultinum/vaultinum-api";
import classNames from "classnames";
import { ElementType } from "react";
import { Color, ColorShade, Size } from "../../../referentials";
import KeyFact, { KeyFactBaseProps } from "../KeyFact";

type CommonBaseProps = Omit<KeyFactBaseProps, "indicator">;
export type KeyFactIconProps = CommonBaseProps & { icon: ElementType; iconColorShade?: ColorShade; iconColor?: Color; color?: Color; colorShade?: ColorShade };
export type KeyFactGradeProps = CommonBaseProps & { grade?: Grade };

const BASE_CLASS_NAME = "flex items-center justify-center rounded-lg";

function getIconSize(color?: Color, small?: boolean): Extract<Size, "sm" | "lg" | "xl"> {
    if (small) {
        return color ? "sm" : "lg";
    }
    return "xl";
}
export namespace KeyFacts {
    export function WithIcon({ icon: Icon, color, small, colorShade, iconColor, iconColorShade, ...props }: KeyFactIconProps) {
        return (
            <KeyFact
                prefix={
                    <div
                        className={classNames(BASE_CLASS_NAME, {
                            [`bg-${color}-primary`]: !!color && !colorShade,
                            [`bg-${color}-${colorShade === "extraLight" ? "extra-light" : colorShade}`]: !!color && !!colorShade,
                            "h-20 w-20": !small,
                            "h-8 w-8": small
                        })}
                    >
                        <Icon size={getIconSize(color, small)} color={iconColor || "slate"} shade={iconColorShade || "light"} />
                    </div>
                }
                small={small}
                {...props}
            />
        );
    }
    export function WithGrade({ grade, small, ...props }: KeyFactGradeProps) {
        return (
            <KeyFact
                prefix={
                    <div
                        className={classNames(BASE_CLASS_NAME, {
                            "bg-grey-light": !grade,
                            "h-20 w-20": !small,
                            "h-10 w-10": small
                        })}
                        {...(grade && { style: { backgroundColor: GradeColors[grade] } })}
                    >
                        <h1 className={classNames("font-bold text-white font-arial", { "text-5xl": !small, "text-2xl": small })}>{grade || "--"}</h1>
                    </div>
                }
                small={small}
                {...props}
            />
        );
    }
}
