import classNames from "classnames";
import { last } from "lodash";
import { ElementType, useEffect, useState } from "react";
import { CheckIcon } from "../Icons";

type Step = {
    key: string;
    title: string;
    subtitle?: string;
    icon?: ElementType;
};

function isCompleted(index: number, currentStepIndex: number): boolean {
    return currentStepIndex < 0 || index < currentStepIndex;
}

function isVisited(index: number, maxVisitedStepIndex: number): boolean {
    return index <= maxVisitedStepIndex;
}

function isCurrent(index: number, currentStepIndex: number): boolean {
    return index === currentStepIndex;
}

function isMaxVisited(index: number, maxVisitedStepIndex: number): boolean {
    return index === maxVisitedStepIndex;
}

function StepperContent({ steps, index, currentStep }: { steps: Step[]; index: number; currentStep: string }): JSX.Element {
    const Icon = steps[index].icon;
    const currentStepIndex = steps.findIndex(step => step.key === currentStep);
    if (isCompleted(index, currentStepIndex)) {
        return <CheckIcon color="white" />;
    }
    if (Icon) {
        return <Icon color={isCurrent(index, currentStepIndex) ? "pink" : "grey"} />;
    }
    return <>{index + 1}</>;
}

export function Stepper({
    steps,
    currentStepKey,
    setCurrentStepKey,
    isEditing,
    isDisabled
}: {
    steps: Step[];
    currentStepKey: string;
    setCurrentStepKey?: (key: string) => void;
    isEditing?: boolean;
    isDisabled?: boolean;
}) {
    const [maxVisitedStepKey, setMaxVisitedStepKey] = useState<string>((isEditing && last(steps)?.key) || currentStepKey);
    const currentStepIndex = steps.findIndex(step => step.key === currentStepKey);
    const maxVisitedStepIndex = steps.findIndex(step => step.key === maxVisitedStepKey);

    useEffect(() => {
        if (currentStepIndex > maxVisitedStepIndex) {
            setMaxVisitedStepKey(currentStepKey);
        }
    }, [currentStepKey, maxVisitedStepKey]);

    function onStepClick(key: string) {
        const index = steps.findIndex(step => step.key === key);
        if (0 <= index && index < steps.length && isVisited(index, maxVisitedStepIndex)) {
            setCurrentStepKey?.(key);
        }
    }

    return (
        <div className="flex">
            {steps.map(({ key, title, subtitle }, index) => {
                const isCurrentStep = isCurrent(index, currentStepIndex);
                const isVisitedStep = isVisited(index, maxVisitedStepIndex);
                const isCompletedStep = isCompleted(index, currentStepIndex);
                const isUpToCurrentStep = isCompletedStep || isCurrentStep;
                return (
                    <div
                        key={index}
                        className={classNames("flex w-full flex-col gap-2", {
                            "text-slate-dark": !isUpToCurrentStep,
                            "text-pink-primary": isUpToCurrentStep
                        })}
                    >
                        <div className="flex items-center">
                            <div
                                className={classNames("h-0.5 grow border-b-2", {
                                    invisible: index === 0,
                                    "border-pink-primary": isUpToCurrentStep,
                                    "border-grey-light": !isCompletedStep && !isCurrentStep,
                                    "border-opacity-30": !isVisitedStep
                                })}
                            />
                            <div
                                className={classNames("flex h-8 w-8 items-center justify-center rounded-full border-2 font-bold", {
                                    "border-pink-primary bg-pink-primary": isCompletedStep,
                                    "border-pink-primary bg-white": isCurrentStep,
                                    "border-grey-light bg-grey-light": !isVisitedStep,
                                    "bg-white": !isCompletedStep,
                                    "opacity-30": !isVisitedStep,
                                    "cursor-pointer": isVisitedStep && !isDisabled
                                })}
                                onClick={() => !isDisabled && onStepClick(key)}
                                children={<StepperContent steps={steps} index={index} currentStep={currentStepKey} />}
                            />
                            <div
                                className={classNames("h-0.5 grow border-b-2", {
                                    invisible: index === steps.length - 1,
                                    "border-pink-primary": isCompletedStep,
                                    "border-grey-light": (!isCompletedStep || isCurrentStep) && index <= steps.length,
                                    "border-opacity-30": !isVisitedStep || isMaxVisited(index, maxVisitedStepIndex)
                                })}
                            />
                        </div>
                        <div
                            className={classNames("flex flex-col text-center", {
                                "opacity-30": !isVisitedStep
                            })}
                        >
                            <span className="text-xs font-bold">{title}</span>
                            {subtitle && <span className="text-xs text-grey-dark">{subtitle}</span>}
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
