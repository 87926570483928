import { Context, createContext, ElementType, ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { LogoProps } from "../../design-system";
import { DrawerLocalStorageService, DrawerService } from "../../services";
import { useAuthContext } from "./AuthContext";

export type AppInfo<TAppCode extends string> = {
    appCode: TAppCode;
    baseUrl: string;
    logo: ElementType<LogoProps>;
};

export type AppsInfos<TAppCode extends string> = Record<TAppCode | "account", AppInfo<TAppCode>>;

export type AppInterface<TAppItem, TAppCode extends string> = {
    appsInfos: AppsInfos<TAppCode>;
    appCode: TAppCode;
    getItem?: (itemId: string, accountId: string | undefined, onUpdate: (item: TAppItem | null) => void) => () => void;
};

type Item<T> = T | null | undefined;

export type BannerType = "info" | "success" | "warning" | "error";

type AppContextInterface<TAppItem extends { id: string }, TAppCode extends string> = {
    selectedItem: Item<TAppItem>;
    appInfo: AppInfo<TAppCode>;
    appsInfos: AppsInfos<TAppCode>;
    selectedItemId: string;
    setLayoutBanner: (banner: { type: BannerType; children: ReactNode } | undefined) => void;
    drawerService: DrawerService;
    layoutBanner?: { type: BannerType; children: ReactNode };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let appContext: Context<AppContextInterface<any, any>> | null = null;
function getAppContext<TAppItem extends { id: string }, TAppCode extends string>(): Context<AppContextInterface<TAppItem, TAppCode>> {
    if (!appContext) {
        appContext = createContext<AppContextInterface<TAppItem, TAppCode>>({} as AppContextInterface<TAppItem, TAppCode>);
    }
    return appContext;
}

export const urlAfter = (url: string, path = "") => url.replace(new RegExp(`.*${path}/`, "g"), "");

export function AppProvider<TAppItem extends { id: string }, TAppCode extends string>({
    children,
    value
}: {
    children: ReactNode;
    value: AppInterface<TAppItem, TAppCode>;
}): JSX.Element {
    const [selectedItem, setSelectedItem] = useState<Item<TAppItem>>(null);
    const [layoutBanner, setLayoutBanner] = useState<{ type: BannerType; children: ReactNode } | undefined>();
    const { pathname } = useLocation();
    const { selectedAccount } = useAuthContext();
    const appInfo = useMemo(() => value.appsInfos[value.appCode], [value.appsInfos, value.appCode]);

    const pathId = useMemo(() => urlAfter(pathname, appInfo.baseUrl).replace(/\/.*/, ""), [pathname, appInfo.baseUrl]);

    useEffect(() => {
        if (!value.getItem) {
            return;
        }
        if (pathId) {
            setSelectedItem(undefined);
            value.getItem(pathId, selectedAccount?.id, setSelectedItem);
        } else {
            setSelectedItem(null);
        }
    }, [pathId, selectedAccount]);

    const exposed: AppContextInterface<TAppItem, TAppCode> = useMemo(
        () => ({
            selectedItem,
            appInfo,
            appsInfos: value.appsInfos,
            selectedItemId: pathId,
            layoutBanner,
            setLayoutBanner,
            drawerService: new DrawerLocalStorageService()
        }),
        [selectedItem, appInfo, value.appsInfos, layoutBanner]
    );

    const Provider = getAppContext<TAppItem, TAppCode>().Provider;
    return <Provider value={exposed} children={children} />;
}

export function useAppContext<TAppItem extends { id: string }, TAppCode extends string>() {
    return useContext(getAppContext<TAppItem, TAppCode>());
}
