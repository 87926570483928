import classNames from "classnames";
import { ReactNode } from "react";
import { InfoCircleIcon } from "../Icons";
import { Tooltip } from "../Tooltip/Tooltip";

export type KeyFactBaseProps = {
    title: string;
    value?: ReactNode;
    prefix?: ReactNode;
    tooltipText?: string;
    grow?: boolean;
    small?: boolean;
    onClick?: () => void;
};

export default function KeyFact({ title, value, prefix, tooltipText, grow, small, onClick }: KeyFactBaseProps): JSX.Element {
    const titleClasses = classNames("font-light text-slate-dark leading-5 truncate", { underline: !!onClick });
    return (
        <div className={classNames("flex items-center gap-2", { "flex-1": grow })}>
            {prefix}
            <div>
                <div className="flex items-center gap-1">
                    {onClick ? <button className={titleClasses} children={title} onClick={onClick} /> : <div className={titleClasses} children={title} />}
                    {tooltipText && <Tooltip children={<InfoCircleIcon color="slate" shade="dark" />} title={tooltipText} />}
                </div>
                <div className={classNames("font-bold text-slate-primary", { "text-lg leading-5": small, "text-3xl": !small })} children={value ?? "--"} />
            </div>
        </div>
    );
}
