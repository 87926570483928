import { FullAudit } from "@vaultinum/vaultinum-api";
import filesize from "filesize";
import { CommonLang } from "../CommonLang";

const locale = "es-ES";
const formatSize = (size = 0): string => filesize(size, { base: 10, locale });

/* eslint max-len: 0 */
export const esCommon: CommonLang = {
    code: "es",
    getLang: "Español",
    apps: { name: "Aplicaciones" },
    shared: {
        // shared between authentication, account and apps
        email: "Correo electrónico",
        copy: "Copiar", // to review
        invalidEmail: "Correo electrónico erróneo",
        name: "Nombre",
        description: "Descripción",
        confirm: "Confirmar",
        cancel: "Cancelar",
        add: "Añadir",
        save: "Guardar",
        edit: "Editar",
        change: "Cambiar",
        remove: "Eliminar",
        delete: "Borrar",
        free: "Gratis",
        next: "Siguiente",
        previous: "Anterior",
        yes: "Sí",
        no: "No",
        skip: "Saltar",
        submit: "Enviar",
        done: "Hecho",
        back: "Atrás",
        unknown: "Desconocido",
        loading: "Cargando...",
        anyQuestion: "¿Alguna pregunta?",
        contactUs: "Contacte con nosotros",
        requiredErrorMessage: "Este campo es obligatorio",
        minErrorMessage: minLength => `Mínimo ${minLength} caracteres`,
        atLeastOneUpperLowercase: "Al menos una mayúscula y una minúscula", // to review
        atLeastOneSpecialCharacter: "Al menos un carácter especial", // to review
        atLeastOneDigit: "Al menos un dígito", // to review
        loginFailed: "Ha fallado el inicio de sesión.",
        logoutFailed: "Ha fallado el cierre de sesión.", // to review
        saveSuccessMessage: "Guardado con éxito",
        saveErrorMessage: "No se ha podido guardar",
        inviteSuccessMessage: "Invitación enviada con éxito",
        inviteErrorMessage: "Fallo en el envío de la invitación",
        sendEmailSuccessMessage: "Correo electrónico enviado con éxito",
        sendEmailErrorMessage: "Fallo en el envío del correo electrónico",
        signIn: "Iniciar sesión",
        invalidBirthdate: "Por favor, especifique una fecha de nacimiento correcta",
        dateFormat: "MM/DD/AAAA",
        successMessage: "Completado",
        failMessage: "Fallo",
        password: "Contraseña",
        confirmPassword: "Confirmar contraseña",
        everything: "Todo",
        updated: "Actualizado",
        update: "Actualización",
        apply: "Aplicar",
        logOutProgress: "Cerrando sesión...",
        emailMustBeDifferentFromYours: "La dirección de correo electrónico debe ser diferente a la tuya",
        details: "Detalles",
        uploadDescription: fullAudit => (fullAudit ? "Deposite manualmente su archivo" : "Deposite uno o varios archivos, archivos comprimidos o carpetas"),
        uploadMethod: "Método de carga",
        uploadTitle: "Subir",
        seeAllItems: appInfoLang => `Ver ${appInfoLang?.allItemsName || "todos"}`,
        overview: "Resumen",
        selectedRepositories: {
            [1]: "1 repositorio seleccionado",
            plural: count => `${count} repositorios seleccionados`
        },
        selectManually: "Seleccionar manualmente",
        upload: "Subir",
        processing: "Procesamiento",
        ready: "Listo",
        noResult: "No hay resultados",
        included: "Incluido", // to review
        search: "Buscar", // to review
        results: {
            [0]: "0 resultado",
            [1]: "1 resultado",
            plural: (count: number, totalCount?: number) => (totalCount && totalCount > 0 ? `${count}/${totalCount} resultados` : `${count} resultados`)
        },
        characters: {
            [0]: "0 caractere",
            [1]: "1 caractere",
            plural: count => `${count} caracteres`
        },
        invalidPhoneNumber: "Número de teléfono inválido",
        other: "Otros",
        filterBy: (label: string) => `Filtrar por ${label.toLocaleLowerCase()}`,
        sortBy: (label: string) => `Ordenar por ${label.toLocaleLowerCase()}`, // to review
        ascending: "Ascendente", // to review
        descending: "Descendente", // to review
        organisation: "Organización",
        domain: "Dominio", // to review
        status: "Estado",
        empty: "vacio", // to review
        months: {
            january: "Enero", // to review
            february: "Febrero", // to review
            march: "Marzo", // to review
            april: "Abril", // to review
            may: "Mayo", // to review
            june: "Junio", // to review
            july: "Julio", // to review
            august: "Agosto", // to review
            september: "Septiembre", // to review
            october: "Octubre", // to review
            november: "Noviembre", // to review
            december: "Diciembre" // to review
        },
        changeFilter: "Para ampliar su búsqueda, cambie o elimine el filtro anterior", // todo to review
        selectAccount: "Seleccione una cuenta", // to review
        failedFetchingAccount: "Error al obtener la cuenta", // to review
        others: "Otros", // to review
        noDataAvailable: "No hay datos disponibles" // to review
    },
    menu: {
        failedToGetMenuItems: "Se produjo un error al cargar el menú." // to review
    },
    date: {
        on: "el",
        doneOn: isFeminine => (isFeminine ? "Realizada el" : "Realizado el"), // to review
        createdOn: isFeminine => (isFeminine ? "Creada el" : "Creado el"),
        updatedOn: isFeminine => (isFeminine ? "Actualizada el" : "Actualizado el"),
        completedOn: isFeminine => (isFeminine ? "Completada el" : "Completado el"),
        requestedOn: isFeminine => (isFeminine ? "Enviada el" : "Enviado el"),
        addedOn: isFeminine => (isFeminine ? "Añadida el" : "Añadido el")
    },
    settingsMenu: {
        signOut: "Desconectarse",
        signedInAs: "Conectado como:",
        companyAccount: "Organización:",
        settings: "Configuración", // to review
        profileSettings: "Configuración del perfil",
        accountSettings: "Configuración de la organización",
        switchTo: "Cambiar a otra organización:",
        manageAccounts: "Gestionar organizaciones",
        newOrganization: "Nueva organización" // to review
    },
    price: {
        allPlans: "Todos los Planos", // to review
        priceOnDemand: "Precio sobre demanda",
        getStarted: "Comenzar",
        title: {
            accessClause: "Cláusula de acceso",
            bipartite: "Acuerdo bipartito",
            tripartite: "Acuerdo tripartito",
            deposit: "Depósito",
            depositUpdate: "Actualización del depósito",
            depositUnlimitedPlan: "Plan de Depósito Ilimitado", // to review
            elementCheckedDeposit: "Depósito Verificado",
            contentCheckedDeposit: "Depósito Controlado",
            oneShot: "One Shot"
        },
        features: {
            fiveSurveys: "Cinco encuestas",
            multiUserAccess: "Acceso a Múltiples Usuarios",
            illustratedReport: "Informe ilustrado",
            recommendations: "Recomendaciones",
            customerSupport: "Soporte al Cliente",
            oneSurvey: "Una encuesta",
            unlimitedNumberOfSurvey: "Encuestas ilimitadas",
            encryptedDeposit: "Depósito encriptado",
            certificateOfDeposit: "Certificado de depósito",
            freeUpdates: "Actualizaciones gratuitas",
            contractTemplate: "Modelo de contrato",
            legalTermsAtYourService: "Un equipo legal a su servicio",
            beneficiaryDashboardAccess: "Acceso al dashboard para sus beneficiarios",
            vaultinumAsSignator: "Vaultinum como signatario",
            oneOnlineAssessment: "Una autoevaluación",
            sourceCodeScan: "Escaneo de código fuente",
            expertAnalysis: "Análisis de expertos",
            multipleProducts: "Múltiples productos",
            multipleCompanies: "Múltiples empresas"
        }
    },
    payment: {
        checkout: {
            name: "Checkout"
        },
        proceed: "Proceder al pago",
        downloadReceipt: "Descargar recibo",
        downloadReceiptError: "Ocurrió un error al descargar el recibo",
        payment: {
            price: "Precio",
            promoCode: "Código promocional",
            promoCodePlaceholder: "Ex: GPBM6PYXE", // to review
            promoCodeError: "Este código de promoción no es válido",
            taxes: taxValue => `Impuestos (${taxValue}% IVA)`,
            discount: "Descuento",
            total: "Total",
            name: "Pago",
            message: "Elija el método de pago:",
            creditCard: {
                name: "Tarjeta de crédito o débito",
                cardName: "Nombre de la tarjeta",
                saveAcceptance:
                    "Al marcar esta casilla, acepta guardar este método de pago para que se le ofrezca en sus futuros pedidos. Sin embargo, no sustituirá a su forma de pago predeterminada actual."
            },
            sepaDebit: {
                name: "Adeudo SEPA",
                accountHolder: {
                    name: "Nombre del titular de la cuenta",
                    namePlaceholder: "Nombre completo"
                },
                iban: "IBAN",
                mandateAcceptance:
                    "Al proporcionar su información de pago y confirmar este pago, usted autoriza (A) a Vaultinum y Stripe, nuestro proveedor de servicios de pago, a enviar instrucciones a su banco para cargar su cuenta y (B) a su banco para cargar su cuenta de acuerdo con esas instrucciones. Como parte de sus derechos, usted tiene derecho a un reembolso de su banco bajo los términos y condiciones de su acuerdo con su banco. El reembolso debe reclamarse en un plazo de 8 semanas a partir de la fecha en que se hizo el cargo en su cuenta. Sus derechos se explican en una declaración que puede obtener de su banco. Acepta recibir notificaciones de futuros cargos hasta 2 días antes de que se produzcan"
            },
            activationCode: {
                name: "Código de activación",
                message: "Introduzca su código de activación",
                placeholder: "Código de activación..."
            },
            method: "Método de pago"
        },
        done: {
            name: "Hecho",
            message: "¡Su compra se ha realizado con éxito!"
        },
        failed: {
            name: "Fallo",
            message: "Hubo un error durante su pago, por favor reintente.",
            backToPayment: "Volver al pago",
            unableToConfirmCard: "No se puede confirmar la tarjeta",
            callbackError:
                "Ha habido un problema al finalizar el pago. Se le ha cobrado. Si intenta pagar de nuevo, es posible que se le cobre dos veces. Póngase en contacto con nosotros para que podamos darle un cupón gratuito antes de volver a intentarlo."
        },
        billing: {
            address1: "Dirección",
            address: "Dirección de facturación",
            billingInformation: "Información de facturación",
            city: "Ciudad",
            companyName: "Nombre de la empresa",
            country: "País",
            vatNumber: "Número de IVA",
            vatNumberPlaceholder: "ESA1234567Z",
            wrongVatFormat: "Formato de IVA incorrecto",
            taxIdRegistered: "Número de IVA ya registrado",
            notProvided: "No proporcionado",
            zipCode: "Código postal",
            billingDisclaimer: "Sus datos se utilizarán para la compra. Por favor, asegúrese de la exactitud de sus datos.",
            formWarningMissingDetails: "Faltan algunos de los datos de facturación requeridos",
            billingContact: "Contacto de facturación",
            billingContactPlaceholder: "Correo electrónico del contacto de facturación"
        },
        frequency: {
            day: "día",
            week: "semana",
            month: "mes",
            year: "año"
        },
        summary: {
            order: "Resumen del pedido",
            billingAddress: "Dirección de facturación",
            paymentMethod: "Método de pago",
            disclaimer: "Recibirá un correo electrónico de confirmación con los detalles del pedido"
        },
        method: {
            expired: "Caducada",
            addPaymentMethod: "Añadir método de pago",
            expirationDate: "Caduca final",
            errorListingPaymentMethods: "Se produjo un error al obtener la lista de métodos de pago",
            errorAddingPaymentMethod: "Se produjo un error al agregar el método de pago" // to review
        }
    },
    industryList: {
        businessConsultancyManagement: "Negocios, consultoría o gestión",
        accountancyBankingFinance: "Contabilidad, banca o finanzas",
        energyUtilities: "Energía y servicios públicos",
        engineeringManufacturing: "Ingeniería o fabricación",
        environmentAgriculture: "Medio ambiente o agricultura",
        softwarePublisher: "Editor de software",
        iTServices: "Servicios informáticos",
        law: "Derecho",
        lawEnforcementSecurity: "Aplicación de la ley y seguridad",
        leisureHospitalityTourism: "Ocio, hostelería o turismo",
        mediaDigitalPublicRelations: "Medios de comunicación, digital, relaciones públicas",
        propertyConstruction: "Propiedad o construcción",
        publicServicesAdministration: "Servicios públicos o administración",
        scientificResearch: "Investigación científica",
        pharmaceuticalsHealthcare: "Productos farmacéuticos, atención sanitaria",
        education: "Educación",
        transportLogistics: "Transporte o logística",
        other: "Otros"
    },
    cookieConsent: {
        accept: "Aceptar",
        decline: "Rechazar",
        description:
            "Utilizamos cookies para analizar el tráfico y mejorar la experiencia del usuario. Puede leer nuestro aviso legal para saber más sobre cómo tratamos los datos."
    },
    report: {
        completed: "Completado",
        industryAverage: "Media del sector",
        score: "Puntuación"
    },
    fileTree: {
        lines: "Líneas",
        size: "Tamaño"
    },
    codeInput: {
        codeValidationMessage: "Verificar el código...",
        codeValidationMessageError: "Código inválido, por favor inténtelo de nuevo.",
        codeValidationMessageSuccess: "Código validado."
    },
    register: {
        acceptTermsConditionsAndPrivacyPolicyErrorMessage: "Deben aceptarse los términos y condiciones y la política de privacidad.",
        alreadyRegistered: "¿Ya se ha registrado?",
        confirmPasswordErrorMessage: "Las contraseñas no coinciden",
        createAccount: "Crear cuenta",
        iAcceptThe: "Acepto las",
        join: company => `Unirse a ${company}`,
        login: "Iniciar sesión",
        logout: "Cerrar sesión",
        privacyPolicy: "la política de privacidad",
        register: "Registro",
        registerWelcome: "Rellene el siguiente formulario para crear una nueva cuenta.",
        termsConditions: "Condiciones generales de uso de la plataforma",
        failMessage: "No se ha podido registrar" // to review
    },
    onboarding: {
        title: "¡Bienvenidos a Vaultinum!",
        subtitle: "Tercero independiente de confianza especializado en la protección y auditoría de activos digitales."
    },
    client: {
        supplier: "Proveedor",
        beneficiary: "Beneficiario"
    },
    fullAudit: {
        errors: {
            notFound: "Due Diligence no encontrada."
        },
        status: {
            [FullAudit.Status.OPEN]: "Abierto",
            [FullAudit.Status.IN_PROGRESS]: "En curso",
            [FullAudit.Status.BLOCKED]: "Bloqueado",
            [FullAudit.Status.CANCELLED]: "Cancelado",
            [FullAudit.Status.DONE]: "Acabado"
        }
    },
    parties: {
        newParty: "Nueva conexión",
        sendInvitation: "Enviar invitación",
        sendInvitationByEmail: "Enviar invitación por correo electrónico",
        inputSupplierEmail: "Por favor, especifique el correo electrónico de su contacto:",
        invitation: companyName =>
            `Invite a su contacto a formar parte de “${companyName}“. Una vez la conexión aceptada, su nuevo contacto también podrá ver la información de escrow y/o auditoría compartida, así como el nombre, la dirección y el número de registro de su empresa. Si su contacto aún no tiene una cuenta en Vaultinum, se le pedirá que cree una. Marque la casilla siguiente si desea que se le notifique por correo electrónico.`,
        alreadyPartner: "Esta dirección de correo electrónico ya es un socio de su organización", // to review
        alreadyReceivedInvitation: "Esta dirección de correo electrónico ya ha recibido una invitación.", // to review
        alreadySentInvitation: "Ya se ha enviado una invitación a esta dirección de correo electrónico.", // to review
        sendInvitationConfirmation: (email: string) => `¿Está seguro de que desea enviar una invitación al correo electrónico siguiente: ${email}?` // to review
    },
    accountInformation: {
        title: "Información de la organización",
        form: {
            companyName: "Nombre de la organización",
            companyRegistrationNumber: "Número de registro",
            companyNationality: "Nacionalidad",
            companyIndustry: "Industria",
            companySize: "Tamaño de la organización",
            address: {
                title: "Dirección de la organización",
                line1: "Dirección",
                line1PlaceHolder: "Nombre de la calle, número",
                line2: "Línea de dirección 2",
                line2PlaceHolder: "Edificio, piso",
                city: "Ciudad",
                postalCode: "Código postal",
                postalCodePlaceHolder: "00000",
                country: "País"
            },
            billing: {
                invoiceCompanyName: "Nombre de la empresa facturada",
                billingInformationInvoiceCompanyName:
                    "Por defecto, el nombre de su empresa se muestra en su factura. Si desea mostrar un nombre personalizado en su lugar, introdúzcalo aquí:",
                billingInformationInvoicePostalAddress: "Si desea añadir una dirección postal diferente a cada factura, introdúzcala aquí",
                billingInformationQuestions: "¿Tiene alguna pregunta sobre su facturación?"
            }
        },
        roleAdminIsRequired: "Es necesario tener un rol de administrador o representante para editar la información de la empresa",
        notes: {
            title: "Notas",
            deleteNote: "Borrar Nota",
            showNotes: "Mostrar notas",
            permanentlyDeleteNote: "Esto borrará permanentemente la nota",
            visibleBy: "Visible por",
            updatedBy: "Última actualización por",
            reviewers: "revisores",
            membersOf: accountName => `miembros de ${accountName}`
        }
    },
    seo: {
        registerTitle: "Regístrese en la plataforma de gestión online",
        registerDescription:
            "Cree una organización en la plataforma online y realice un depósito de IP, gestione sus contratos de escrow o realice una due diligence tecnológica | ISO27001",
        loginTitle: "Acceda a nuestra plataforma de gestión online",
        loginDescription:
            "Acceda a la plataforma online y realice un depósito de propiedad intelectual, gestione sus contratos de escrow o realice una due diligence tecnológica",
        homeTitle: "¿Qué acción desea realizar?",
        homeDescription:
            "La plataforma Vaultinum permite a los usuarios hacer un depósito de PI, gestionar un contrato de escrow de software o ejecutar una due diligence tecnológica"
    },
    git: {
        gitConnection: "Conexión Git",
        description: fullAudit => {
            if (fullAudit) {
                return "Sube tu código directamente desde un proveedor Git";
            } else {
                return "Sincroniza tus repositorios de código a intervalos regulares o según eventos de git";
            }
        },
        notConfigured: "Parece que aún no ha establecido una conexión Git",
        notConfiguredDetails:
            "Para configurar su sistema de control de versiones distribuido en línea (GitHub, por ejemplo), haga clic en el botón de abajo y siga las instrucciones. Vuelva aquí cuando haya terminado",
        selectSource: "Seleccionar fuente",
        selectRepository: "Seleccionar directorio(s) de código",
        selectBranch: "Seleccionar rama",
        selectFrequency: "Selecciona una frecuencia",
        defaultBranch: "Rama por defecto",
        frequency: {
            weekly: "Semanal",
            monthly: "Mensual",
            every3Month: "Cada 3 meses",
            every6month: "Cada 6 meses",
            oneShot: "One shot"
        },
        connectionActive: "Conexión git activa",
        errors: {
            connectionSuspended: "Conexión git suspendida",
            connectionUninstalled: "Conexión git desinstalada",
            connectionNotAccessible: "Conexión git no accesible",
            repositoryNotAccessible: "Repositorio git no accesible",
            repositoryNotFound: "Repositorio git no encontrado",
            branchDeleted: "Rama eliminada",
            archiveSizeExceeded: "El tamaño del archivo supera el límite",
            operationFailed: "Operación git fallida"
        },
        addRepository: "Añadir repositorio",
        addAllRepositories: "Agregar todos los directorios de código",
        branch: "rama",
        selectedRepositories: "Repositorios seleccionados",
        warningCheckoutConfirmation: "Una vez confirmado, ya no podrá editar el título, la descripción y los repositorios seleccionados",
        frequencyTitle: "Frecuencia",
        summaryError: "Ocurrió un error al recuperar la información",
        codeRepository: "Directorio de código",
        source: "Fuente",
        repositoriesError: "Se produjo un error al recuperar los directorios de código",
        setup: "Configuración",
        codeRepositoryEmpty: "Agregue algunos repositorios de código y seleccione una sucursal"
    },
    table: {
        apply: "Aplicar", // to review
        search: "Buscar", // to review
        filter: "Filtrar", // to review
        sort: "Ordenar", // to review
        selectAll: "Seleccionar todo", // to review
        clearAll: "Borrar todo", // to review
        sortAsc: "Ordenar por orden ascendente", // to review
        sortDesc: "Ordenar por orden descendente" // to review
    },
    email: {
        subject: {
            billingSupportRequest: "Solicitud de soporte - facturación", // to review
            supportRequest: "Solicitud de soporte" // to review
        }
    },
    uploadFile: {
        description: (fileType: string) => `Seleccione o arrastre y suelte su archivo ${fileType}`, // to review
        file: "Archivo", // to review
        size: "Tamaño", // to review
        noFileUploaded: "No se ha subido ningún archivo", // to review
        preview: "Vista previa", // to review
        formatSize,
        tooManyFiles: "Sólo se acepta un archivo", // to review
        invalidFileType: (fileName: string, fileTypes: string) => `El archivo ${fileName} debe ser uno de los tipos ${fileTypes}`, // to review
        acceptedFileTypes: (fileTypes: string) => `El tipo de archivo no es compatible. Sólo se aceptan: ${fileTypes}`, // to review
        actions: "Acciones", // to review
        minResolution: (resolution: number) => `Tamaño mínimo recomendado: ${resolution}px (ancho/alto)`,
        errorFetchingFiles:
            "Se ha producido un error al obtener los archivos de nuestro servidor. Por favor, póngase en contacto con nosotros para solucionar este problema antes de intentar subir los archivos de nuevo",
        errorWhileUploading: "Error al subir su archivo. Por favor, póngase en contacto con nuestro servicio de atención al cliente",
        files: "Archivos",
        alreadyExists: {
            [0]: "ya existe",
            [1]: "ya existe",
            plural: () => "ya existen"
        },
        maxFileSizeError: (filename, maxFileSize) => `${filename} es mayor que ${maxFileSize}`,
        maxNumberOfFilesError: maxNumberOfFiles => `No puedes subir más de ${maxNumberOfFiles} archivos`,
        maxTotalFileSizeError: maxTotalFileSize => `No puede subir más de ${maxTotalFileSize} archivos`,
        minNumberOfFilesError: "Tienes que subir al menos un archivo",
        noFilesUploadedYet: "No hay archivos subidos todavía",
        notPossibleToDelete: filename => `No fue posible borrar ${filename}`,
        progress: "Progreso",
        uploadInstructions: "Suelte los archivos aquí o", // to review
        uploadLimitations: (acceptedFileTypes, maxFileSize, maxTotalSize) =>
            `${
                !acceptedFileTypes?.length ? "Todos los tipos de archivos son aceptados" : `Solo se aceptan ciertos tipos de archivos: ${acceptedFileTypes}`
            }. Ningún archivo puede ser mayor de ${maxFileSize}. El tamaño total no puede exceder de ${maxTotalSize}.`,
        waitUntilFinished: "Tienes que esperar hasta que la subida haya terminado",
        browse: "Buscar" // to review
    },
    chart: {
        monthAbbreviation: "m", // to review
        yearAbbreviation: "a" // to review
    }
};
