import classNames from "classnames";
import fileSize from "filesize";
import { sumBy } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { Button, Buttons, Column, DeleteIcon, openNotificationWithIcon, Progress, Table, UploadIcon } from "../../../design-system";
import { useLang } from "../../../lang";
import {
    DEFAULT_UPLOADER_MAX_FILE_SIZE,
    DEFAULT_UPLOADER_MAX_TOTAL_FILES,
    DEFAULT_UPLOADER_MAX_TOTAL_SIZE,
    EnrichedUploadFiles,
    getUploadErrorMessage,
    UPLOADER_CANCEL_MESSAGE,
    UploaderProps
} from "../../helpers";

function UploadProgress({ fileName, filesUploadData }: { fileName: string; filesUploadData: EnrichedUploadFiles[] }): JSX.Element {
    const progress = filesUploadData.find(fileData => fileData.file.name === fileName)?.progress ?? 0;
    return (
        <div className="w-28">
            <Progress size="small" percent={Math.floor(progress)} />
        </div>
    );
}

function DeleteButton({
    filesUploadData,
    isLoading,
    onDelete,
    fileId
}: {
    filesUploadData: EnrichedUploadFiles[];
    isLoading: boolean;
    onDelete: (fileId: string) => Promise<void>;
    fileId: string | undefined;
}): JSX.Element {
    const progress = filesUploadData.find(fileData => fileData.id === fileId)?.progress ?? 0;
    return <Buttons.Icon isLoading={isLoading} size="sm" icon={DeleteIcon} isDisabled={progress < 100 || !fileId} onClick={() => fileId && onDelete(fileId)} />;
}

export function Uploader({ setIsWorking, deleteFile, uploadFile, isFileValid, files, onFilesChanged, options }: Readonly<UploaderProps>): JSX.Element {
    const lang = useLang();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [enrichedFiles, setEnrichedFiles] = useState<EnrichedUploadFiles[]>([]);

    const {
        uploadLimitationsText,
        fileTypes,
        accept,
        maxFileSize = DEFAULT_UPLOADER_MAX_FILE_SIZE,
        maxTotalSize = DEFAULT_UPLOADER_MAX_TOTAL_SIZE,
        maxTotalFiles = DEFAULT_UPLOADER_MAX_TOTAL_FILES
    } = options.file;
    const { isDisabled, isUploadOnDropEnabled } = options.uploader ?? {};

    function updateFileProgress(filename: string, progress: number): void {
        setEnrichedFiles(prevEnrichedFiles =>
            prevEnrichedFiles.map(enrichedFile => {
                if (enrichedFile.file.name === filename) {
                    return { ...enrichedFile, progress };
                }
                return enrichedFile;
            })
        );
    }

    function removeFileFromList(fileId: string): void {
        setEnrichedFiles(prevFiles => {
            const updatedFiles = prevFiles.filter(fileData => fileData.id !== fileId);
            onFilesChanged?.(updatedFiles);
            return updatedFiles;
        });
    }

    async function processQueue(filesToUpload: EnrichedUploadFiles[]): Promise<void> {
        try {
            const pendingFiles = filesToUpload.filter(fileData => fileData.progress === 0);
            for (const fileUpload of pendingFiles) {
                const fileId = await uploadFile(
                    fileUpload.file,
                    updateFileProgress,
                    (fileName: string, errorMessage: string) => {
                        if (errorMessage === UPLOADER_CANCEL_MESSAGE) {
                            return;
                        }
                        openNotificationWithIcon({ type: "error", description: lang.uploadFile.errorWhileUploading });
                        removeFileFromList(fileName);
                    },
                    fileUpload.abortController
                );

                if (fileId) {
                    setEnrichedFiles(prevFiles =>
                        prevFiles.map(fileData => {
                            if (fileData.file.name === fileUpload.file.name) {
                                return { ...fileData, id: fileId };
                            }
                            return fileData;
                        })
                    );
                }
            }
        } catch {
            openNotificationWithIcon({ type: "error", description: lang.uploadFile.errorWhileUploading });
        }
    }

    useEffect(() => {
        (async () => {
            if (files?.every(fileData => fileData.id)) {
                setIsLoading(true);
                try {
                    setEnrichedFiles((files ?? []).map(filesWithId => ({ file: filesWithId.file, size: filesWithId.size, progress: 100, id: filesWithId.id })));
                } catch {
                    openNotificationWithIcon({ type: "error", description: lang.uploadFile.errorFetchingFiles });
                } finally {
                    setIsLoading(false);
                }
            }
        })();
    }, [files]);

    const totalSize = useMemo(() => Object.values(enrichedFiles).reduce((sum, fileData) => sum + fileData.size, 0), [enrichedFiles]);

    useEffect(() => {
        setIsWorking(Object.values(enrichedFiles).some(({ progress }) => (progress ? progress < 100 : true)));
    }, [enrichedFiles]);

    const onDrop = useCallback(
        async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
            const acceptedFilesTotalSize = sumBy(acceptedFiles, "size");

            const uploadValidationData = {
                acceptedFiles,
                fileRejections,
                files: enrichedFiles.map(({ file }) => file),
                totalSize,
                acceptedFilesTotalSize,
                lang,
                options: options.file
            };

            const errorMessage = getUploadErrorMessage(uploadValidationData);
            if (errorMessage) {
                openNotificationWithIcon({ type: "error", description: errorMessage });
                return;
            }

            const newEnrichedFiles = acceptedFiles
                .filter(newFile => isFileValid?.(newFile))
                .map(newFile => ({
                    file: newFile,
                    size: newFile.size,
                    progress: 0,
                    abortController: new AbortController(),
                    id: undefined
                }));

            setEnrichedFiles(prevFiles => [...prevFiles, ...newEnrichedFiles]);
            if (isUploadOnDropEnabled) {
                await processQueue(newEnrichedFiles);
            }
        },
        [enrichedFiles, totalSize, options.file]
    );

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, open } = useDropzone({
        noClick: true,
        onDrop,
        maxSize: maxTotalSize,
        maxFiles: maxTotalFiles,
        accept,
        disabled: isDisabled
    });

    async function doDelete(fileId: string): Promise<void> {
        setIsLoading(true);
        try {
            await deleteFile?.(fileId);
            removeFileFromList(fileId);
        } catch {
            openNotificationWithIcon({ type: "error", description: lang.uploadFile.notPossibleToDelete(fileId) });
        } finally {
            setIsLoading(false);
        }
    }

    const tableColumns: Column<EnrichedUploadFiles>[] = useMemo(() => {
        const columns: Column<EnrichedUploadFiles>[] = [
            { header: lang.uploadFile.files, cell: cell => cell.row.original.file.name },
            { header: lang.uploadFile.size, accessorKey: "size", cell: cell => fileSize(cell.getValue<number>()) },
            {
                header: lang.uploadFile.progress,
                cell: cell => <UploadProgress fileName={cell.row.original.file.name} filesUploadData={enrichedFiles} />
            }
        ];

        if (deleteFile) {
            columns.push({
                header: lang.uploadFile.actions,
                cell: cell => <DeleteButton fileId={cell.row.original.id} filesUploadData={enrichedFiles} isLoading={isLoading} onDelete={doDelete} />
            });
        }

        return columns;
    }, [enrichedFiles, isLoading]);

    return (
        <div className="flex flex-col gap-4 w-full">
            <div
                className={classNames("rounded-md border-dashed border-grey-dark border p-4 flex flex-col gap-4 items-center", {
                    "border-slate-primary": isDragActive,
                    "border-green-primary": isDragAccept,
                    "border-red-primary": isDragReject,
                    "opacity-75": isDragActive,
                    "bg-grey-extra-light cursor-not-allowed": isDisabled,
                    "bg-white": !isDisabled
                })}
                {...getRootProps()}
            >
                <input {...getInputProps()} />
                <div className="flex gap-4 items-center">
                    <UploadIcon color="slate" size="md" />
                    <p>{lang.uploadFile.uploadInstructions}</p>
                    <Button isLoading={false} isDisabled={isDisabled} type="default" children={lang.uploadFile.browse} onClick={open} />
                </div>
                <p className="text-xs font-light text-center">
                    {uploadLimitationsText ??
                        lang.uploadFile.uploadLimitations(
                            fileTypes?.join(", ") ?? "",
                            lang.uploadFile.formatSize(maxFileSize),
                            lang.uploadFile.formatSize(maxTotalSize)
                        )}
                </p>
            </div>
            <Table columns={tableColumns} data={enrichedFiles} />
        </div>
    );
}
